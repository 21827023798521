
import { Action, Getter } from "vuex-class";
import { Component, Ref, Vue } from "vue-property-decorator";
import { FormBase, FormError, FormSubmit } from "@/components/forms";
import { formatErrors } from "@/utils/formatters";
import { bus } from "@/main";
import { WorkStatus } from '@/store/modules/activity.store';

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        FormBase,
        FormError,
        FormSubmit,
    },
})
export default class StartupActivityModal extends Vue {
    @Getter("activity/viewing") activity!: Activity;
    @Action("activity/patch") patchActivity!: (payload: ActivityPatchPayload) => Activity;

    @Ref() form!: FormClass;

    payload = {};

    show = false;

    errorResponse: ErrorResponse = {
        status: 0,
        errors: [],
    };

    mounted() {
        bus.$off("show-startup-activity");
        bus.$on("show-startup-activity", () => {
            this.show = true;
        });
    }

    async submit(form: FormClass) {
        try {
            await this.patchActivity({ id: this.activity.id, work_status: WorkStatus.newDossier });

            this.$toast.open({ message: this.$t("views.prospects.started") as string, type: "success", position: 'bottom-left' });

            this.handleClose();
        } catch (e) {
            this.errorResponse = formatErrors(e);
        } finally {
            form.reset();
        }
    }

    handleClose() {
        this.errorResponse = {
            status: 0,
            errors: [],
        };

        this.show = false;
    }
}
